import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { FAQPage, Thing } from "schema-dts";
import { mapGenericContent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { RVOFaqComponentProps } from "./RVOFaqComponentInterfaces";
import { useRVOFaqComponentStyles } from "./RVOFaqComponentStyles";

const RVOFaqComponent = (props: RVOFaqComponentProps): JSX.Element | null => {
  const classes = useRVOFaqComponentStyles();
  const [expanded, setExpanded] = useState<string | null>(null);

  const handleChange = useCallback(
    (panel: string) =>
      (
        event: React.ChangeEvent<Record<string, unknown>>,
        isExpanded: boolean
      ) => {
        setExpanded(isExpanded ? panel : null);
      },
    []
  );

  // Shouldn't really be an optional prop.
  if (!props.faqs) {
    return null;
  }

  const faqStructuredDataMainEntities: Thing[] = props.faqs.map((question) => {
    const questionFields = question?.questionFields;

    return {
      "@type": "Question",
      name: questionFields?.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: questionFields?.answer,
      },
    };
  });

  const faqStructuredData = (
    <Helmet
      script={[
        helmetJsonLdProp<FAQPage>({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: [...faqStructuredDataMainEntities],
        }),
      ]}
    />
  );

  return (
    <>
      {faqStructuredData}

      <Box>
        {props.faqs.map((question, index) => {
          const questionFields = question?.questionFields;
          const questionId = question?.id;
          return (
            <Accordion
              expanded={expanded === questionId}
              onChange={handleChange(questionId || "")}
              key={questionId}
            >
              <AccordionSummary
                expandIcon={
                  expanded === questionId ? (
                    <RemoveIcon className={classes.expandIconMin} />
                  ) : (
                    <AddIcon className={classes.expandIconMax} />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ content: classes.summary }}
                className={classes.accSummary}
              >
                <Typography
                  className={classes.count}
                  variant="h3"
                  component="p"
                >
                  {(index + 1).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </Typography>

                <Typography className={classes.heading} variant="h3">
                  {questionFields?.question}
                </Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.accDetails}>
                <Typography className={classes.details} component="div">
                  {replaceHtmlWithFunctionComponent(
                    questionFields?.answer || "",
                    mapGenericContent
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};

export default RVOFaqComponent;
