import {
  BusinessTwoTone,
  EmojiObjectsTwoTone,
  EuroSymbolTwoTone,
  FastForwardTwoTone,
  SecurityTwoTone,
} from "@material-ui/icons";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

export const benefitsEmployeeParticipation = [
  {
    text: "Rechtssicherheit durch anwaltliche und steuerliche Beratung",
    icon: <SecurityTwoTone />,
  },
  {
    text: "Auf Mitarbeiterbeteiligung spezialisiertes Expertenteam aus Rechtsanwälten für Gesellschaftsrecht, Arbeitsrecht und Steuerberatern",
    icon: <EmojiObjectsTwoTone />,
  },
  {
    text: "Begleitung von der Auswahl des passenden Mitarbeiterbeteiligungsmodells bis zur konkreten Umsetzung im Unternehmen",
    icon: <BusinessTwoTone />,
  },
  {
    text: "Schnelle und unkomplizierte Umsetzung mit der Resolvio-App",
    icon: <FastForwardTwoTone />,
  },
  { text: "Transparente Kosten", icon: <EuroSymbolTwoTone /> },
];

export enum MbPricingButtonVariants {
  Primary = "primary",
  Warning = "warning",
}

export enum MbPricingPackages {
  InitialConsultation = "InitialConsultation",
  Startup = "Startup",
  Corporate = "Corporate",
  Enterprise = "Enterprise",
}

export const mbPricingPackageNames: Record<MbPricingPackages, string> = {
  [MbPricingPackages.InitialConsultation]: "Kostenlose Erstberatung",
  [MbPricingPackages.Startup]: "Startup",
  [MbPricingPackages.Corporate]: "Corporate",
  [MbPricingPackages.Enterprise]: "Enterprise",
};

export interface MbPricingPackage {
  isHighlighted: boolean;
  title: string;
  description: string;
  price: number | JSX.Element;
  priceExplanation: string;
  buttonLabel: string;
  buttonColor?: MbPricingButtonVariants;
  features: string[];
  package: MbPricingPackages;
}

const initialConsultationForFree = "Jetzt kostenlose Erstberatung buchen";

export const mbPricingPackages: Record<MbPricingPackages, MbPricingPackage> = {
  [MbPricingPackages.InitialConsultation]: {
    title: mbPricingPackageNames[MbPricingPackages.InitialConsultation],
    isHighlighted: true,
    description:
      "Erfahre mehr zu den Möglichkeiten, die optimale Mitarbeiterbeteiligung mit Resolvio umzusetzen. Kostenlos und unverbindlich.",
    price: 0,
    priceExplanation: "Der perfekte Kick-Off für deine Mitarbeiterbeteiligung.",
    buttonLabel: initialConsultationForFree,
    buttonColor: MbPricingButtonVariants.Warning,
    features: [
      "30min Erstberatung durch einen unserer Mitarbeiterbeteiligungs-Experten",
    ],
    package: MbPricingPackages.InitialConsultation,
  },
  [MbPricingPackages.Startup]: {
    title: mbPricingPackageNames[MbPricingPackages.Startup],
    isHighlighted: false,
    description: "Nutze Anteile für die Bezahlung deiner Mitarbeiter",
    price: 6000,
    priceExplanation: "Für Startups. Netto.*",
    buttonLabel: initialConsultationForFree,
    buttonColor: MbPricingButtonVariants.Warning,
    features: [
      "Rechtliche und steuerliche Beratung zu den gängigen Mitarbeiterbeteiligungs-Modellen durch spezialisierte Resolvio-Partneranwälte",
      "Zurverfügungstellung von standardisierten Mitarbeiterbeteiligungs-Verträgen",
      "Informationsmaterial für die Mitarbeiter",
      "Unterstützung bei der Umsetzung im Unternehmen mit der Resolvio-App",
    ],
    package: MbPricingPackages.Startup,
  },
  [MbPricingPackages.Corporate]: {
    title: mbPricingPackageNames[MbPricingPackages.Corporate],
    isHighlighted: false,
    description:
      "Binde deine Mitarbeiter mit Anteilen dauerhaft an dein Unternehmen.",
    price: 12000,
    priceExplanation: "Für etablierte Unternehmen. Netto.*",
    buttonLabel: initialConsultationForFree,
    buttonColor: MbPricingButtonVariants.Warning,
    features: ['Leistungsumfang wie bei "Startup"'],
    package: MbPricingPackages.Corporate,
  },
  [MbPricingPackages.Enterprise]: {
    title: mbPricingPackageNames[MbPricingPackages.Enterprise],
    isHighlighted: false,
    description: "Gerne erstellen wir dir ein individuelles Angebot",
    price: <QuestionAnswerIcon style={{ fontSize: 45 }} />,
    priceExplanation: "Für komplexere Strukturen.",
    buttonLabel: initialConsultationForFree,
    buttonColor: MbPricingButtonVariants.Warning,
    features: [
      "Individualisierte Ausarbeitung eines Mitarbeiterbeteiligungs-Programms",
    ],
    package: MbPricingPackages.Enterprise,
  },
};
