import { createStyles, makeStyles } from "@material-ui/core";
import { fontWeightBold } from "../../../../layouts/theme";

export const useRVODiscountBadgeStyles = makeStyles((theme) =>
  createStyles({
    discountBadgeWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
    },
    discountBadge: {
      width: "fit-content",
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      fontWeight: fontWeightBold,
      transform: "translate(0, -15px)",
      ziIndex: 10,
    },
  })
);
