import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@material-ui/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { benefitsEmployeeParticipation } from "./RVOPricingMitarbeiterbeteiligungTemplateData";
import { useRVOPricingMitarbeiterbeteiligungPageStyles } from "./RVOPricingMitarbeiterbeteiligungTemplateStyles";

const RVOMitarbeiterbeteiligungBenefits = (): JSX.Element => {
  const classes = useRVOPricingMitarbeiterbeteiligungPageStyles();
  const theme = useTheme();

  return (
    <Box style={{ padding: 0, margin: 0 }}>
      <List className={classes.pricingPackageFeatureList}>
        {benefitsEmployeeParticipation.map((benefit) => (
          <ListItem
            alignItems="flex-start"
            disableGutters={true}
            key={benefit.text}
            style={{ gap: theme.spacing(1) }}
          >
            <ListItemIcon style={{ color: theme.palette.warning.dark }}>
              {benefit.icon}
            </ListItemIcon>

            <ListItemText>{benefit.text}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default RVOMitarbeiterbeteiligungBenefits;
