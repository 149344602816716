import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { accordionSpacing, fontFamilySerif } from "../../../layouts/theme";

export const useRVOFaqComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      color: theme.palette.primary.dark,
      marginLeft: theme.spacing(4),
      marginBottom: 0,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        paddingTop: theme.spacing(1),
      },
    },
    accDetails: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: "75%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 0, 2, 0),
      },
    },
    details: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      color: theme.palette.primary.dark,
      [theme.breakpoints.down("sm")]: {},
      [theme.breakpoints.down("xs")]: {},
    },
    count: {
      fontFamily: fontFamilySerif,
      position: "absolute",
      top: theme.spacing(accordionSpacing + 1),
      left: theme.spacing(accordionSpacing),
      lineHeight: theme.spacing(1.5),
      fontSize: theme.spacing(1.5),
      color: theme.palette.primary.dark,
      [theme.breakpoints.down("xs")]: {
        top: theme.spacing(1),
      },
    },
    summary: {
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
    accSummary: {
      [theme.breakpoints.down("xs")]: {},
    },
    expandIconMax: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: "50%",
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      padding: theme.spacing(0.5),
    },
    expandIconMin: {
      color: theme.palette.background.default,
      fontSize: theme.spacing(2),
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "50%",
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      padding: theme.spacing(0.5),
    },
    sectionTitle: {
      width: "100%",
      textAlign: "center",
    },
  })
);
