import { createStyles, makeStyles } from "@material-ui/core";

export const useRVOPricingCalculationStyles = makeStyles((theme) =>
  createStyles({
    oldPrice: {
      textDecoration: "line-through",
      color: theme.palette.grey[500],
    },
  })
);
