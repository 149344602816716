import { Box, Typography } from "@material-ui/core";
import { assertTruthy } from "@nvon/baseline";
import { startupNonProfitDiscount } from "../../../../helpers/clientSide/constants";
import { PricingPackage } from "../RVOPricingTemplateData";
import { useRVOPricingCalculationStyles } from "./RVOPricingCalculationStyles";

export interface RVOPricingCalculationProps {
  pricingPackage: Pick<PricingPackage, "price">;
  isShowingStartupDiscount: boolean;
  isShowingYearlyDiscount: boolean;
  round?: boolean;
}

const RVOPricingCalculation = (
  props: RVOPricingCalculationProps
): JSX.Element => {
  const classes = useRVOPricingCalculationStyles();
  const {
    pricingPackage: { price },
    round = false,
  } = props;

  assertTruthy(
    typeof price === "number",
    "This component can only be used for packages with a price number."
  );

  const finalPrice = getPricingPackagePrice(props);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ gap: 20 }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignContent="flex-end"
        style={{ gap: 10 }}
      >
        <Typography variant="h2" component="span">
          {formatPrice(finalPrice, round)}
        </Typography>

        {finalPrice < price && (
          <Typography variant="body1" className={classes.oldPrice}>
            {formatPrice(price, round)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const formatPrice = (price: number, round: boolean): string => {
  // When rounding, better to show higher prices,
  //  so nobody can complain afterwards.
  const rounded = round ? Math.ceil(price) : price;

  const result = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(rounded);

  // 29,00 --> 29,-
  return result.replace(",00", ",-");
};

const getPricingPackagePrice = ({
  isShowingStartupDiscount: applyStartupDiscount,
  isShowingYearlyDiscount: applyYearlyPaymentDiscount,
  pricingPackage: { price },
}: RVOPricingCalculationProps): number => {
  if (typeof price !== "number") {
    return 0;
  }

  let finalPrice = price;

  if (applyStartupDiscount) {
    finalPrice *= 1 - startupNonProfitDiscount / 100;
  }

  if (applyYearlyPaymentDiscount) {
    finalPrice = (finalPrice * 10) / 12;
  }

  return finalPrice;
};

export default RVOPricingCalculation;
