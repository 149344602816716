import { Box, Chip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { startupNonProfitDiscount } from "../../../helpers/clientSide/constants";
import { useRVODiscountBadgeStyles } from "../RVOPricingTemplate/RVODiscountBadge/RVODiscountBadgeStyles";
import {
  MbPricingPackage,
  mbPricingPackageNames,
} from "./RVOPricingMitarbeiterbeteiligungTemplateData";

export interface RVODiscountBadgeProps {
  isShowingStartupDiscount: boolean;
  isShowingYearlyDiscount: boolean;
  pricingPackage: MbPricingPackage;
}

const RVODiscountBadge = ({
  isShowingStartupDiscount,
  isShowingYearlyDiscount,
  pricingPackage,
}: RVODiscountBadgeProps): JSX.Element => {
  const classes = useRVODiscountBadgeStyles();
  const { t } = useTranslation();

  const getBadgeContent = (
    startupDiscount: boolean,
    yearlyDiscount: boolean
  ) => {
    if (startupDiscount && yearlyDiscount) {
      return t("pricing.discountBadge.bothDiscounts", {
        discount: startupNonProfitDiscount,
      });
    }

    if (startupDiscount) {
      return t("pricing.discountBadge.onlyStartupDiscount", {
        discount: startupNonProfitDiscount,
      });
    }

    if (yearlyDiscount) {
      return t("pricing.discountBadge.onlyYearlyDiscount");
    }

    return "";
  };

  return (
    <Box>
      {(isShowingStartupDiscount || isShowingYearlyDiscount) &&
        pricingPackage.title !== mbPricingPackageNames.InitialConsultation && (
          <Box className={classes.discountBadgeWrapper}>
            <Chip
              label={getBadgeContent(
                isShowingStartupDiscount,
                isShowingYearlyDiscount
              )}
              className={classes.discountBadge}
            />
          </Box>
        )}
    </Box>
  );
};

export default RVODiscountBadge;
