import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { PopupButton } from "@typeform/embed-react";
import { clsx } from "clsx";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import RVOTypeformPopupButton from "../../01-atoms/RVOTypeformPopupButton/RVOTypeformPopupButton";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import RVOFaqComponent from "../../03-organisms/RVOFaqComponent/RVOFaqComponent";
import {
  BackgroundColors,
  HeadlineComponent,
} from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextInterfaces";
import RVOFeaturedTextSection from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextSection";
import RVOPricingCalculation from "../RVOPricingTemplate/RVOPricingCalculation/RVOPricingCalculation";
import RVODiscountBadge from "./RVODiscountBadge";
import RVOMitarbeiterbeteiligungBenefits from "./RVOMitarbeiterbeteiligungBenefits";
import {
  MbPricingButtonVariants,
  MbPricingPackages,
  mbPricingPackages,
} from "./RVOPricingMitarbeiterbeteiligungTemplateData";
import { useRVOPricingMitarbeiterbeteiligungPageStyles } from "./RVOPricingMitarbeiterbeteiligungTemplateStyles";

const RVOPricingMitarbeiterbeteiligungTemplate = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useRVOPricingMitarbeiterbeteiligungPageStyles();

  const isShowingStartupDiscount = true;
  const isShowingYearlyDiscount = false;

  const theme = useTheme();

  const pricingMitarbeiterbeteiligungFaqs:
    | readonly GatsbyTypes.WpQuestionFieldsFragment[]
    | undefined = [
    {
      __typename: "WpQuestion",
      id: "1",
      title: "",
      slug: "",
      questionFields: {
        question: t("mitarbeiterbeteiligung.faq.supportedBoardTypes.question"),
        answer: t("mitarbeiterbeteiligung.faq.supportedBoardTypes.answer"),
      },
      seo: undefined, // Add the missing 'seo' property
      kategorienFragen: undefined, // Add the missing 'kategorienFragen' property
    },
    {
      __typename: "WpQuestion",
      id: "2",
      title: "",
      slug: "",
      questionFields: {
        question: t(
          "mitarbeiterbeteiligung.faq.supportedOrganizationTypes.question"
        ),
        answer: t(
          "mitarbeiterbeteiligung.faq.supportedOrganizationTypes.answer"
        ),
      },
      seo: undefined, // Add the missing 'seo' property
      kategorienFragen: undefined, // Add the missing 'kategorienFragen' property
    },
    {
      __typename: "WpQuestion",
      id: "3",
      title: "",
      slug: "",
      questionFields: {
        question: t("mitarbeiterbeteiligung.faq.supportedCountries.question"),
        answer: t("mitarbeiterbeteiligung.faq.supportedCountries.answer"),
      },
      seo: undefined, // Add the missing 'seo' property
      kategorienFragen: undefined, // Add the missing 'kategorienFragen' property
    },
  ];

  const sanitzedTitle = t("mitarbeiterbeteiligung.pricingPageName").replace(
    /<\/?[^>]+(>|$)/g,
    ""
  );

  return (
    <>
      <Helmet>
        <title>{sanitzedTitle}</title>

        <meta
          name="description"
          content={t("mitarbeiterbeteiligung.pricingPageIntroText")}
        />
      </Helmet>

      <RVOFeaturedTextSection
        pretext={t("mitarbeiterbeteiligung.pretitle")}
        backgroundColored={BackgroundColors.BlueLight}
        headlineCentered={true}
        wide={true}
        pretextComponent={HeadlineComponent.H1}
        xxlHeadline={true}
        headline={t("mitarbeiterbeteiligung.pricingPageName")}
      >
        <Container
          maxWidth="md"
          style={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(4),
          }}
        >
          <RVOTypeformPopupButton
            typeformId="XBnGXEmZ"
            label={t("employeeShareOwnership.cta")}
          />

          <Box
            style={{
              margin: `0 10%`,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: theme.spacing(4),
            }}
          >
            <Typography variant="body1">
              {t("mitarbeiterbeteiligung.pricingPageIntroText")}
            </Typography>

            <Box>
              <Typography variant="h3">
                <HtmlFromLangFile
                  contentContainingHtml={t(
                    "mitarbeiterbeteiligung.benefits.title"
                  )}
                />
              </Typography>

              <RVOMitarbeiterbeteiligungBenefits />
            </Box>
          </Box>

          <Typography variant="body1">
            <HtmlFromLangFile
              contentContainingHtml={t("mitarbeiterbeteiligung.discountClaim")}
            />
          </Typography>

          <Grid container={true} spacing={2}>
            {Object.values(mbPricingPackages).map((pricingPackage) => (
              <Grid
                key={pricingPackage.title}
                item={true}
                xs={12}
                md={6}
                lg={3}
              >
                <Box
                  className={clsx(
                    pricingPackage.isHighlighted
                      ? classes.pricingPackageHighlightedCard
                      : undefined
                  )}
                >
                  <Box className={classes.pricingPackageHighlightedBox}>
                    <Typography
                      className={clsx(
                        pricingPackage.isHighlighted
                          ? classes.show
                          : classes.hide,
                        classes.pricingPackageHighlightedText
                      )}
                    >
                      {t("mitarbeiterbeteiligung.recommended")}
                    </Typography>
                  </Box>

                  <Card className={classes.pricingPackageCard}>
                    {(pricingPackage.package === MbPricingPackages.Startup ||
                      pricingPackage.package ===
                        MbPricingPackages.Corporate) && (
                      <RVODiscountBadge
                        isShowingStartupDiscount={isShowingStartupDiscount}
                        isShowingYearlyDiscount={isShowingYearlyDiscount}
                        pricingPackage={pricingPackage}
                      />
                    )}

                    <CardContent>
                      <Box
                        marginBottom={1}
                        className={classes.pricingPackageTitleWrapper}
                      >
                        <Typography
                          className={classes.pricingPackageTitle}
                          variant="h3"
                          component="h2"
                        >
                          {pricingPackage.title}
                        </Typography>

                        <Typography
                          variant="body1"
                          className={classes.pricingPackageDescription}
                        >
                          {pricingPackage.description}
                        </Typography>
                      </Box>

                      <Box marginBottom={1} textAlign="center">
                        <Box
                          marginBottom={1}
                          className={classes.pricingPackagePriceWrapper}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box>
                            {typeof pricingPackage.price === "number" ? (
                              <RVOPricingCalculation
                                round={true}
                                isShowingStartupDiscount={
                                  isShowingStartupDiscount
                                }
                                isShowingYearlyDiscount={
                                  isShowingYearlyDiscount
                                }
                                pricingPackage={pricingPackage}
                              />
                            ) : (
                              pricingPackage.price
                            )}
                          </Box>

                          <Typography
                            className={classes.pricingPackageExplanation}
                            variant="body2"
                            component="p"
                          >
                            {pricingPackage.priceExplanation}
                          </Typography>
                        </Box>

                        <PopupButton
                          id="XBnGXEmZ"
                          className={clsx(
                            pricingPackage.buttonColor ===
                              MbPricingButtonVariants.Warning
                              ? classes.warningButton
                              : undefined,
                            classes.pricingPackageButton
                          )}
                          size={70}
                        >
                          {pricingPackage.buttonLabel}
                        </PopupButton>

                        <List className={classes.pricingPackageFeatureList}>
                          {pricingPackage.features.map((feature) => (
                            <ListItem
                              alignItems="flex-start"
                              disableGutters={true}
                              key={feature}
                            >
                              <ListItemIcon>
                                {<CheckCircleIcon color="primary" />}
                              </ListItemIcon>

                              <ListItemText>{feature}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Typography variant="body2">
            {t("mitarbeiterbeteiligung.disclaimer")}
          </Typography>
        </Container>
      </RVOFeaturedTextSection>

      <RVOContainer
        background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
        noPaddingTop={true}
        paddingBottom={true}
      >
        <RVOFaqComponent faqs={pricingMitarbeiterbeteiligungFaqs} />
      </RVOContainer>
    </>
  );
};

export default RVOPricingMitarbeiterbeteiligungTemplate;
